import { GetStaticProps } from 'next';

import {
  AllContentPagesDocument,
  AllContentPagesQuery,
  ContentPageDocument,
  ContentPageQuery
} from 'graphql/generated';

import DatoPage from 'components/DatoPage';

import { datoRequest } from 'lib/datoSDK';

interface IDynamicPageProps {
  contentPageQuery: ContentPageQuery;
}

const DynamicPage = ({ contentPageQuery }: IDynamicPageProps) => {
  if (contentPageQuery) {
    return (
      <DatoPage
        seo={contentPageQuery.contentPage?.seo}
        contentBoxes={contentPageQuery.contentPage?.content}
        navItems={contentPageQuery.allNavitems}
      />
    );
  }
};

export const getStaticPaths = async () => {
  const allContentPagesQuery = await datoRequest<AllContentPagesQuery>(AllContentPagesDocument);

  const paths = allContentPagesQuery.allContentPages.map((page) => ({
    params: { slug: page.slug?.split('/') }
  }));

  return { paths, fallback: false };
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const slug = !params?.slug ? '' : (params.slug as string[]).join('/');

  const contentPageQuery = await datoRequest<ContentPageQuery>(ContentPageDocument, { slug: slug });

  if (!contentPageQuery.contentPage) {
    return {
      notFound: true
    };
  }

  return {
    props: {
      contentPageQuery
    },
    revalidate: false
  };
};

export default DynamicPage;
